.wrapper {
  display: inline-block;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 0 .5rem;
}

.button {
  background-color: transparent;
  border: none;
  display: inline-block;
  font-size: 1rem;
  margin-right: .25rem;
  padding: .5rem .25rem;
}

.button:hover {
  text-decoration: underline;
}

.button:disabled,
.button[disabled] {
  text-decoration: none;
}
