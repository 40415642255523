.uploader {
  height: 100%;
}

.uploader > div {
  height: 100%;
}

.uploader .uppy-DragDrop-inner {
  padding: 0px !important;
}

.uploader .uppy-DragDrop-label {
  font-size: 1rem !important;
}
