.menuLabel {
  font-weight: bold;
  margin: 0 0 .5rem 1rem;
  text-transform: uppercase;
  font-size: .8rem;
  opacity: .6;
}

.menuList {
  margin: 0 0 1rem 0;
  padding: 0;
}

.menuItem {
  display: block;
  padding: 0.25rem 1rem;
}

.active {
  background-color: var(--special-color);
}
