.field {
  display: flex;
  padding: .5rem 0;
}

.form {
  margin: .5rem;
  background-color: white;
  padding: .5rem;
  max-width: calc(740px + 1.5rem);
}

.controlWrapper {
  width: 100%;
}

.control {
  border-radius: .25rem;
  font-size: 1rem;
  max-width: 600px;
  width: 100%;
}

.control[type='text'] {
  border: 1px solid var(--border-color);
  padding: .5rem;
}

textarea.control {
  height: 10rem;
  border: 1px solid var(--border-color);
  padding: .5rem;
}

select.control {
  height: 2rem;
  background-color: white;
  border: 1px solid var(--border-color);
}

.label {
  width: 140px;
  padding: .5rem;
}
