.empty {
  margin: .5rem;
  padding: .5rem;
  background-color: white;
}

.error {
  margin: .5rem;
  padding: .5rem;
  background-color: white;
  border: 1px solid var(--danger-color);
  color: var(--danger-color);
}

.loading {
  margin: .5rem;
  padding: .5rem;
  background-color: white;
}
