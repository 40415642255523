.wrapper {
  border: 1px solid var(--border-color);
  border-radius: .25rem;
  padding: 1px .5rem .5rem .5rem;
  width: 600px;
}

.wrapper:focus-within {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.toolbar {
  background-color: white;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: .5rem;
  padding-top: .5rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.toolbar > * {
  display: inline-block;
}

.toolbar > * + * {
  margin-left: .5rem;
}

.editor {
  line-height: 1.4;
  min-height: 10rem;
}

.editor code {
  background-color: #eee
}

.editor blockquote {
  margin-left: 0;
  padding-left: 1rem;
  border-left: 4px solid #eee;
}

.editor li {
  margin-left: 1.5rem;
}

.image {
  max-width: 100%;
}

.block {
  padding: .25rem
}

.active {
  outline: 1px solid var(--border-color);
}
