.button {
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: .25rem;
  color: var(--text-color);
  display: inline-block;
  font-size: .8rem;
  padding: .5rem 1rem;
}

.button:hover {
  background-color: var(--background-color);
}

.button:focus {
  outline-offset: -2px;
}

.button + .button {
  margin-left: 1rem;
}

.button {
  text-decoration: none;
}

.close {
  border: 0;
  color: var(--border-color);
  font-size: 1rem;
}

.close:hover {
  color: var(--text-color);
  cursor: pointer;
}

.close:active {
  outline: 0;
}

.error {
  border-color: var(--danger-color);
  color: var(--danger-color);
}
