.backdrop {
  background-color: var(--text-color);
  opacity: .15;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
}

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1030;
}

.header {
  padding: .5rem 1rem;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: bold;
  font-size: 1.2rem;
}

.wrapper {
  position: relative;
  width: calc(600px + 5.25rem);
  margin: 30px auto;
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: .25rem;
  z-index: 1050;
}
