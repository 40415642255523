/* colors from https://mdbootstrap.com/docs/jquery/css/colors/ */
:root {
  --primary-color: #4285F4;
  --primary-color-dark: #0d47a1;
  --danger-color: #ff4444;
  --danger-color-dark: #CC0000;
  --warning-color: #ffbb33;
  --warning-color-dark: #FF8800;
  --success-color: #00C851;
  --success-color-dark: #007E33;
  --info-color: #33b5e5;
  --info-color-dark: #0099CC;
  --special-color: #37474F;
  --special-color-dark: #263238;

  --background-color: #eceff1; /* #efefef */;
  --border-color: #cfd8dc; /* #ccc */
  --text-color: #263238;
}

body {
  color: var(--text-color);
}

.app {
  display: grid;
  grid-template-areas: "app-nav app-body";
  grid-template-columns: 12rem 1fr;
  height: 100vh;
}

.app-nav {
  --text-color: var(--background-color);
  color: var(--text-color);
  grid-area: app-nav;
  background-color: var(--special-color-dark);
  overflow-y: auto;
  /* border-right: 1px solid var(--border-color); */
}

.app-body {
  grid-area: app-body;
}

.main {
  display: flex;
}

.main-nav {
  flex: 0 0 12rem;
  background-color: white;
  border-right: 1px solid var(--border-color);
  overflow-y: auto;
}

.main-body {
  flex: 1;
  background-color: var(--background-color);
  height: 100vh;
}

.main-info {
  flex: 0 0 10rem;
}

.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.page-header {
  flex: 0;
  border-bottom: 1px solid var(--border-color);
}

.page-nav {
  flex: 0;
  background-color: white;
  border-bottom: 1px solid var(--border-color);
}

.page-body {
  overflow-y: auto;
  flex: 1;
}

.page-content {

}

.page-info {

}

table {
  width: 100%;
}

th {
  text-align: left;
}

a {
  text-decoration: none;
  color: var(--primary-color-dark);
}
