.wrapper {
  height: 100vh;
  display: flex;
  background-color: var(--background-color);
  align-items: center;
  justify-content: center;

}

.wrapper svg {
  width: 100px;
  height: 100px;
  margin: 20px;
  display: inline-block;
}

.content {
  background: white;
  padding: .5rem 1rem;;
}
