.files {
  display: flex;
  flex-wrap: wrap;
  padding: 0.25rem;
}

.file {
  border-radius: 0.25rem;
  background-color: white;
  margin: 0.25rem;
  padding: 0.5rem;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.filename {
  overflow: hidden;
  padding-top: 0.5rem;
  text-overflow: ellipsis;
  width: 200px;
}

.thumbnail img {
  width: 100%;
}

.thumbnail {
  width: 200px;
  height: 200px;
  overflow: hidden;
  margin-bottom: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
}

.fileinfo {
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  max-width: 600px;
}

.selected {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
