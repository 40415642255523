.wrapper {
  padding: .5rem
}
.handle {
  background-color: var(--border-color);
  width: .5rem;
}
.item {
  align-items: stretch;
  background-color: white;
  display: flex;
  margin-bottom: .5rem;
  width: calc(740px + 2rem);
}
.fields {
  padding: .5rem 0;
}
.actions {
  align-self: flex-start;
}
