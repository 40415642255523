.wrapper {
  margin: .5rem;
  background-color: white;
  padding: .5rem;
  display: flex;
  align-items: center;
}

.label {
  padding: .5rem;
}

.control {
  width: 330px;
}

.input {
  border-radius: .25rem;
  font-size: 1rem;
  width: 300px;
  border: 1px solid var(--border-color);
  padding: .5rem;
  margin-right: .25rem;
}

.select {
  width: 200px
}
