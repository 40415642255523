.wrapper {
  margin: 0;
}

.list {
  padding: 0;
  margin: .25rem 0 .5rem .5rem;
}

.item {
  list-style: none;
}

.link {
  color: #111;
  display: block;
  padding: .25rem;
  text-decoration: none;
}

.active {
  border-right: 2px solid var(--text-color);
}
