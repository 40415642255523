.wrapper {
  margin-top: 1rem;
  display: flex;
  width: 600px;
  padding-left: .75rem;
}

.valid {
  border-left: 4px solid var(--success-color-dark);
}

.invalid {
  border-left: 4px solid var(--danger-color-dark);
}

.image {
  flex: 0 1;
  margin-right: .75rem;
}

.mediumImage {
  display: block;
  width: 200px;
}

.smallImage {
  display: block;
  width: 60px;
}

.info {

}

.name {
  font-weight: bold;
}

.description {
  margin-top: .25rem;
}
