.button {
  font-size: .8rem;
  border: none;
  border-bottom: 2px solid white;
  padding: .5rem 1rem;
  outline: none;
}

.button:hover {
  color: var(--primary-color);
}

.active {
  border-bottom: 2px solid var(--primary-color);
  color: var(--primary-color);
}
