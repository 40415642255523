.footer {
  text-align: right;
  padding: .5rem;
}

.actions {
  padding: 0 .5rem;
}

.header {
  align-items: center;
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.header h1 {
  font-size: 1.5rem;
  margin: 0.5rem 1rem;
}

.main-header {
  grid-area: header;
  border-bottom: 1px solid var(--border-color);
}

.main-header h1 {
  margin: .5rem 1rem;
}

.editor-pane {
  display: flex;
  flex-direction: column;
  background-color: #eee;
  border-right: 1px solid var(--border-color);
}

.preview-pane {
  grid-area: preview;
}

.preview-frame {
  width: 100%;
  height: 100%;
  border: 0;
}
