.accountInfo {
  padding: 1rem;
}

.brand {
  font-size: 1.2rem;
  font-weight: bolder;
}

.user {
  opacity: .8;
  font-size: .9rem
}

.wrapper {
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.wrapper nav {
  display: flex;
  flex-direction: column;
}

.wrapper li {
  list-style: none;
}

.wrapper nav li ul {
  margin-left: 1rem;
}

.wrapper a {
  text-decoration: none;
  color: var(--text-color);
}
