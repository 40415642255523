.wrapper {
  margin: .5rem;
  background-color: white;
  padding: .5rem;
}

.table td, .table th {
  padding: .5rem;
}

.actions {
  display: flex;
}

.actions a {
  display: block;
  color: var(--border-color);
}

.actions a:hover {
  color: var(--text-color);
}

.status {
  color: white;
  font-size: .8rem;
  padding: .25rem;
  border-radius: .25rem;
}

.draft {
  background-color: var(--warning-color-dark);
}

.published {
  background-color: var(--success-color-dark);
}
