.pagination {
  margin: .5rem;
  background: white;
  display: flex;
  padding: .5rem;
  list-style: none;
  border-radius: .25rem;
}

.pageItem {

}

.pageLink {
  color: -webkit-focus-ring-color;
  display: block;
  padding: .5rem;
}

.disabled .pageLink {
  color: grey;
}

.active .pageLink {
  color: var(--text-color);
}
